import _NetworkError from "./NetworkError";
var exports = {};
const NetworkError = _NetworkError;
/**
 * Wrapper around window.fetch that throws a NetworkError when appropriate
 */

exports = function fetchWithNetworkError(...options) {
  return fetch(...options).catch(err => {
    if (err.name === "AbortError") {
      throw err;
    } else {
      throw new NetworkError(err);
    }
  });
};

export default exports;